<template>
  <div class="page-wrapper">
    <!-- USA -->
    <div v-if="destination === 'usa'" class="covid-bar">
      <p v-if="pageData && $route && $route.meta.lang === 'fr'">
        <a href="https://www.tremblant.ca/montagne-village/mesures-sanitaires?sc_lang=fr&webSyncID=ac747cd4-2b91-da11-8933-2e820a33d703&sessionGUID=520ea54e-d249-3d68-7c42-6a2db699565d&_gl=1*sx90bn*_ga*MTE0OTI2MDUzMS4xNjM3MDA3ODM0*_ga_B1YVPN56M6*MTY0NTcxODYwOC4xMDguMS4xNjQ1NzIxMDU0LjA.&_ga=2.55605292.661231445.1645620016-1149260531.1637007834&_gac=1.162419150.1642702079.Cj0KCQiAraSPBhDuARIsAM3Js4qKco1P7CJSNUpHzdpzOIBCIvXlo3IUme-5rRx-bViwslDidkvuRkEaAh5HEALw_wcB" target="_blank">Assouplissements en cours</a>. Expérience 100% dès le 14 mars!
      </p>
      <p v-else-if="pageData && $route && $route.meta.lang === 'en'">
        <a href="https://www.tremblant.ca/mountain-village/health-measures?sc_lang=en&webSyncID=ac747cd4-2b91-da11-8933-2e820a33d703&sessionGUID=520ea54e-d249-3d68-7c42-6a2db699565d&_gl=1*sx90bn*_ga*MTE0OTI2MDUzMS4xNjM3MDA3ODM0*_ga_B1YVPN56M6*MTY0NTcxODYwOC4xMDguMS4xNjQ1NzIxMDU0LjA.&_ga=2.55605292.661231445.1645620016-1149260531.1637007834&_gac=1.162419150.1642702079.Cj0KCQiAraSPBhDuARIsAM3Js4qKco1P7CJSNUpHzdpzOIBCIvXlo3IUme-5rRx-bViwslDidkvuRkEaAh5HEALw_wcB#tab=travel-instructions" target="_blank">Easing of restrictions for entering Canada.</a> Complete Tremblant experience as of March 14!
      </p>
    </div>

    <!-- DEFAULT -->
    <div v-else class="covid-bar">
      <p v-if="pageData && $route && $route.meta.lang === 'fr'">
        <a href="https://www.tremblant.ca/montagne-village/mesures-sanitaires" target="_blank">Assouplissements en cours</a>. Expérience 100% dès le 14 mars!
      </p>
      <p v-else-if="pageData && $route && $route.meta.lang === 'en'">
        <a href="https://www.tremblant.ca/mountain-village/health-measures?sc_lang=en" target="_blank">Easing of health measures in progress.</a> Complete Tremblant experience as of March 14!
      </p>
    </div>

    <header class="main-header covid-actif">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant" />
        <img v-if="$route.meta.lang === 'fr'" src="@/assets/img/tagline-fr.svg" class="tagline" alt="Tagline Tremblant" />
        <img v-else-if="$route.meta.lang === 'en'" src="@/assets/img/tagline-en.svg" class="tagline" alt="Tagline Tremblant" />
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18887381777">1-888-738-1777</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang"
            >FR</router-link
          >
        </div>
        <a href="#" class="cta red" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
      </div>
      <a href="tel:18887381777" class="phone-mobile"><img src="@/assets/img/phone.svg" alt="Call Tremblant" /></a>
      <div class="mobile-cta">
        <a href="#" class="cta red" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
      </div>
    </header>

    <section class="hero flash-mars">
      <img v-if="$route.meta.lang === 'fr'" src="@/assets/img/pastille-numero-1-fr.svg" class="pastille" alt="#1 dans l'est par ski magazine" />
      <img v-else-if="$route.meta.lang === 'en'" src="@/assets/img/pastille-numero-1-en.svg" class="pastille" alt="#1 in theeast by ski magazine" />
      <div class="img-mobile"></div>

      <div class="deal-wrap special-flash" data-inview="slideLeft" data-delay="100">
        <div class="top">
          <img src="@/assets/img/curve-green.svg" alt="Curve" />
          <div class="data"><h2 class="big-title" v-html="$t('site.slogan')"></h2></div>
        </div>
        <div class="bottom">
          <div class="deal">
            <h2 class="small-title" v-html="$t('site.flash-mars-ski-hero-usa')" v-if="destination === 'usa'"></h2>
            <h2 class="small-title" v-html="$t('site.flash-mars-ski-hero')" v-else></h2>
            <a :href="$t('site.flash-mars-link')" target="_blank" class="cta blanc-bleu">{{ $t('site.achetez-short') }}</a>
            <a v-scroll-to:-80 href="#offre-wrap" class="regular-text" @click.prevent="table2">{{ $t('site.show-details') }}</a>
          </div>
          <div class="countdown-wrap">
            <p class="small-title" v-html="$t('site.flash-mars-cut')"></p>
            <!-- <p class="small-title">{{ $t('site.countdown-title') }}</p> -->
            <vue-countdown v-slot="{ days, hours, minutes, seconds }" :time="timeBeforeEvent">
              <div class="countdown">
                <div class="bloc">
                  <div class="number">
                    <p>{{ days }}</p>
                  </div>
                  <p class="regular-text">{{ $t('site.countdown-jour') }}</p>
                </div>
                <div class="bloc">
                  <div class="number">
                    <p>{{ hours }}</p>
                  </div>
                  <p class="regular-text">{{ $t('site.countdown-heure') }}</p>
                </div>
                <div class="bloc">
                  <div class="number">
                    <p>{{ minutes }}</p>
                  </div>
                  <p class="regular-text">{{ $t('site.countdown-minute') }}</p>
                </div>
                <div class="bloc">
                  <div class="number">
                    <p>{{ seconds }}</p>
                  </div>
                  <p class="regular-text">{{ $t('site.countdown-seconde') }}</p>
                </div>
              </div>
            </vue-countdown>
          </div>
        </div>

        <div class="split">
          <div class="deal">
            <h2 class="small-title" v-html="$t('site.flash-mars-heb-hero')"></h2>
          </div>
          <div class="actions">
            <a href="#" class="cta blanc-bleu" @click.prevent="showlodgingform">{{ $t('site.reservez-short') }}</a>
            <a v-scroll-to:-80 href="#offre-wrap" class="regular-text" @click.prevent="table1">{{ $t('site.show-details') }}</a>
          </div>
        </div>
      </div>

      <div class="snow-background">
        <img src="@/assets/img/hero-snow-2.png" class="img-snow" alt="Snow" />
      </div>
    </section>

    <section id="ancre-privileges" class="privileges dink" data-inview>
      <div class="title">
        <div data-inview="fadeInUp" data-delay="200">
          <lottie-player background="transparent" speed="1" autoplay :data-src="'/json/privilege-icon.json'" class="lottie-icon"> </lottie-player>
        </div>
        <h3 class="big-title" data-inview="fadeInUp" data-delay="400" v-html="$t('site.privilege-title')"></h3>
      </div>
      <div class="video-wrap" data-inview="scale" data-delay="500">
        <!-- <a :href="$t('site.privilege-video')" class="glightbox"><img src="@/assets/img/play.svg" class="play" alt="play"></a> -->
        <h4 class="medium-title" v-html="$t('site.privilege-price')"></h4>
        <div class="bottom" @click.prevent="showsidepanel">
          <p class="regular-text">{{ $t('site.privilege-more') }}</p>
          <img src="@/assets/img/plus.svg" class="plus" alt="plus" />
        </div>
      </div>
      <div class="title meilleur" data-inview>
        <div data-inview="fadeInUp" data-delay="200">
          <lottie-player background="transparent" speed="1" autoplay :data-src="'/json/meilleur-icon.json'" class="lottie-icon"> </lottie-player>
        </div>
        <h3 class="big-title" data-inview="fadeInUp" data-delay="400" v-html="$t('site.dispo-title')"></h3>
      </div>
      <div class="politique-box" data-inview="fadeInUp" data-delay="600" @click.prevent="showpolitiquepanel">
        <img src="@/assets/img/icon-politique.svg" class="icon" alt="Plus" />
        <p class="small-title" v-html="$t('site.politique-title')"></p>
        <img src="@/assets/img/plus.svg" class="plus" alt="Plus" />
      </div>
    </section>

    <section class="video-wrap">
      <h3 class="big-title space-bottom" data-inview="fadeInUp" data-delay="300" v-html="$t('site.flash-mars-best-1')"></h3>
      <div class="video" data-inview="fadeInUp" data-delay="400">
        <a :href="$t('site.flash-mars-video-link')" class="glightbox"><img src="@/assets/img/play.svg" class="play" alt="play" /></a>
      </div>
    </section>

    <section id="offre-wrap" class="offre-wrap flash-mars lodging" :class="{ ski: !toggle }">
      <div class="img-mobile"></div>

      <div class="offre" data-inview="slideLeft" data-delay="300">
        <div class="top">
          <img src="@/assets/img/curve-red.svg" alt="Curve" />
        </div>
        <div class="bottom">
          <div class="toggle">
            <p :class="{ actif: toggle }" @click.prevent="table1">{{ $t('site.lodging') }}</p>
            <p :class="{ actif: !toggle }" @click.prevent="table2">Ski</p>
          </div>

          <div id="tab1-link" class="content" :class="{ actif: toggle }">
            <div v-html="$t('site.flash-mars-heb-data')"></div>
            <a href="#" class="cta blanc-rouge" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
            <a v-if="$route.meta.lang === 'fr'" href="/hiver/2122/flash/terms-flash-mars-heb/" class="regular-text modalite">{{ $t('site.modalites') }}</a>
            <a v-else-if="$route.meta.lang === 'en'" href="/en/hiver/2122/flash/terms-flash-mars-heb/" class="regular-text modalite">{{
              $t('site.modalites')
            }}</a>
          </div>

          <div id="tab2-link" class="content" :class="{ actif: !toggle }">
            <div v-html="$t('site.flash-mars-ski-data-usa')"  v-if="destination === 'usa'"></div>
            <div v-html="$t('site.flash-mars-ski-data')"  v-else></div>
            <a :href="$t('site.flash-mars-link')" target="_blank" class="cta blanc-rouge">{{ $t('site.achetez-long') }}</a>
            <a v-if="$route.meta.lang === 'fr'" href="/hiver/2122/flash/terms-flash-mars-ski/" class="regular-text modalite">{{ $t('site.modalites') }}</a>
            <a v-else-if="$route.meta.lang === 'en'" href="/en/hiver/2122/flash/terms-flash-mars-ski/" class="regular-text modalite">{{
              $t('site.modalites')
            }}</a>
          </div>
        </div>
      </div>
    </section>

    <section class="cross-sell split">
      <div class="bloc-half" data-inview="fadeInUp" data-delay="300">
        <div class="img">
          <img src="@/assets/img/vols.jpg" alt="Vols Tremblant Porter" />
        </div>
        <div class="data">
          <h3 class="medium-title" v-html="$t('site.vols-title')"></h3>
          <p class="regular-text" v-html="$t('site.vols-desc')"></p>
          <a :href="$t('site.vols-link')" target="_blank" class="cta blanc-bleu">{{ $t('site.vols-cta') }}</a>
        </div>
      </div>
      <div class="bloc-half" data-inview="fadeInUp" data-delay="400">
        <div class="img">
          <img src="@/assets/img/ecole-neige.jpg" alt="École sur neige Tremblant" />
        </div>
        <div class="data">
          <h3 class="medium-title" v-html="$t('site.ecole-title')"></h3>
          <p class="regular-text" v-html="$t('site.ecole-data')"></p>
          <a :href="$t('site.ecole-link')" target="_blank" class="cta blanc-bleu">{{ $t('site.ecole-cta') }}</a>
        </div>
      </div>
    </section>

    <news-letter></news-letter>

    <footer-bar></footer-bar>

    <div class="newsletter-button" :class="{ active: newsletter, force: newsletterforce }">
      <a v-scroll-to:-80 href="#newsletter" class="wrap" @click.prevent="hidenewsletter">
        <img src="@/assets/img/mail.svg" alt="Mail" />
        <p>{{ $t('site.news-alert') }}</p>
      </a>
      <img src="@/assets/img/close-blue.svg" class="close" alt="close" @click.prevent="hidenewsletter" />
    </div>

    <div class="overlay-form-loding" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform" />
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              v-model="time1"
              name="arrivaldate"
              :lang="this.datepickerLang"
              :disabled-date="disabledBeforeToday"
              :input-attr="{ name: 'arrivaldate', required: 'required' }"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              v-model="time2"
              name="departuredate"
              :lang="this.datepickerLang"
              :disabled-date="disabledBeforeToday"
              :input-attr="{ name: 'departuredate', required: 'required' }"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input name="adultcount" type="number" value="2" min="1" required />
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input name="childCount" type="number" value="0" required />
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')" />
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>

    <div class="overlay-side-panel" :class="{ show: sidepanel }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidesidepanel" />
      <div class="side-panel">
        <div class="img dink"></div>
        <div class="data" v-html="$t('site.privilege-data')"></div>
      </div>
      <div class="opacity" @click.prevent="hidesidepanel"></div>
    </div>

    <div class="overlay-side-panel" :class="{ show: politiquepanel }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidepolitiquepanel" />
      <div class="side-panel">
        <div class="img politique"></div>
        <div class="data" v-html="$t('site.politique-data')"></div>
      </div>
      <div class="opacity" @click.prevent="hidepolitiquepanel"></div>
    </div>
  </div>
</template>

<script>
import { isNil } from 'lodash'
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'
import VueCountdown from '@chenfengyuan/vue-countdown'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker, VueCountdown },
  head() {
    return {
      title: {
        inner: this.$t(`site.flash-mars-meta-title`),
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: this.$t(`site.flash-mars-meta-title`) },
        {
          name: 'description',
          content: this.$t(`site.flash-mars-meta-desc`),
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: this.$t(`site.flash-mars-meta-title`) },
        { name: 'twitter:card', content: 'Tremblant' },
        {
          n: 'twitter:description',
          c: this.$t(`site.flash-mars-meta-desc`),
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t(`site.flash-mars-meta-title`) },
        { property: 'og:site_name', content: 'Tremblant' },
        {
          property: 'og:description',
          content: this.$t(`site.flash-mars-meta-desc`),
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://promo.tremblant.ca/vue-app-static-img/img/flash/mars.jpg',
        },
        {
          name: 'twitter:image',
          content: 'https://promo.tremblant.ca/vue-app-static-img/img/flash/mars.jpg',
        },
      ],
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      toggle: true,
      politiquepanel: false,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      newsletter: false,
      newsletterforce: false,
      time1: new Date(),
      time2: null,
      destination: 'cad',
    }
  },
  watch: {
    $route: {
      handler($route) {
        if (!isNil($route)) {
          if ($route.hash) {
            setTimeout(() => {
              if ($route.hash === '#privileges') {
                const $target = document.querySelector('#ancre-privileges')
                $target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
                return
              }
              if ($route.hash === '#tab2') {
                this.table2()
              }
              const $target = document.querySelector('#offre-wrap')
              $target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
            }, 755)
          }

          if ($route.query && $route.query.destination === 'usa') {
            this.destination = 'usa'
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    timeBeforeEvent() {
      const event = {
        year: 2022,
        month: 3,
        day: 3,
        hour: 13,
        minute: 0,
        second: 0,
      }
      return new Date(event.year, event.month - 1, event.day, event.hour, event.minute, event.second).getTime() - new Date().getTime()
    },
    ...mapState('app', ['appTitle']),
  },
  mounted() {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
    window.addEventListener('scroll', () => {
      this.actionnewsletter()
      this.snowscroll()
    })
  },
  methods: {
    table1() {
      this.toggle = true
    },
    table2() {
      this.toggle = false
    },
    showlodgingform() {
      if (this.formlodging) {
        this.formlodging = false
      } else {
        this.formlodging = true
      }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    hidenewsletter() {
      this.newsletterforce = true
    },
    actionnewsletter() {
      const st = document.documentElement.scrollTop

      if (st >= 400 && !this.newsletterforce) {
        this.newsletter = true
      } else {
        this.newsletter = false
      }
    },
    showsidepanel() {
      if (this.sidepanel) {
        this.sidepanel = false
      } else {
        this.sidepanel = true
      }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    showpolitiquepanel() {
      if (this.politiquepanel) {
        this.politiquepanel = false
      } else {
        this.politiquepanel = true
      }
    },
    hidepolitiquepanel() {
      this.politiquepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${-150 + st / 2}px`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
